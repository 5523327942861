<template>
  <v-app-bar
    app
    elevate-on-scroll>
    <v-toolbar-title class="mouse-pointer" @click="onGoTo('App:Dashboard')">
      Maridela
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-icon v-if="appIsOffline" class="mr-4 red--text">mdi-wifi-off</v-icon>
    <v-btn icon class="mr-4" style="transform: scaleX(-1);" @click="onToggleDarkMode">
      <v-icon>mdi-{{ darkMode ? 'white-balance-sunny' : 'weather-night' }}</v-icon>
    </v-btn>
    <v-progress-circular v-if="pendingAuthentication" width="2" size="20" indeterminate></v-progress-circular>
    <template v-else>
      <router-link v-if="user" :to="{ name: 'App:Dashboard' }">
        <v-btn icon>
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </router-link>
      <router-link v-else :to="{ name: 'Authentication:Login' }">
        <v-btn depressed>Εισοδος</v-btn>
      </router-link>
    </template>

  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  name: 'Header',

  data () {
    return {
      darkMode: true
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
      pendingAuthentication: 'pendingAuthentication'
    }),

    ...mapGetters('network', {
      appIsOffline: 'isOffline'
    })
  },

  created () {
    this.setTheme();
  },

  methods: {

    onGoTo (routeName) {
      this.$router.push({ name: routeName });
    },

    onToggleDarkMode () {
      this.darkMode = !this.darkMode;
      this.$vuetify.theme.dark = this.darkMode;
      localStorage.setItem('theme', this.darkMode ? 'dark' : 'light');
    },

    setTheme () {
      const theme = localStorage.getItem('theme');
      this.darkMode = theme === 'dark';
      this.$vuetify.theme.dark = this.darkMode;
    }
  }

};
</script>
