import { isString as _isString, isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash';
import moment from 'moment';

interface UserCredentials {
  email: string;
  password: string;
  passwordRepeat?: string;
  name?: string;
}

interface UserI {
  id: string;
  email: string;
  name: string;
  subscribed: boolean;
  subscribed_until: number | null;
  blocked: boolean;
  created_at: number;
}
class User implements UserI {
  public id = '';
  public email = '';
  public name = '';
  public subscribed = false;
  public subscribed_until: number | null = null;
  public blocked = false;
  public created_at = moment().valueOf();

  static validateCredentials (credentials: UserCredentials) {
    return _isString(credentials.email) && !_isEmpty(credentials.email.trim()) &&
      (credentials.name
        ? _isString(credentials.name) && !_isEmpty(credentials.name.trim())
        : true) &&
    _isString(credentials.password) && !_isEmpty(credentials.password.trim()) && credentials.password.length >= 6 &&
    credentials.passwordRepeat
      ? (_isString(credentials.passwordRepeat) && !_isEmpty(credentials.passwordRepeat.trim()) && _isEqual(credentials.password, credentials.passwordRepeat))
      : true;
  }
}

export default User;
export { User, UserCredentials };
