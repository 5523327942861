import codes from './promo-codes.store';

const state = {};
const getters = {};
const mutations = {};
const actions = {};
const modules = {
  codes
};

export default { namespaced: true, state, getters, mutations, actions, modules };
