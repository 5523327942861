import User from '@/shared/models/user/User.model';
import firebase from 'firebase/app';
import * as authHelpers from '@/shared/helpers/auth.helper';
import moment from 'moment';
import collections from '@/shared/configs/firebase/database-collections.config';
import { cloneDeep as _cloneDeep, pick as _pick } from 'lodash';

interface State {
  user: User | null;
  isAuthenticated: boolean;
  pendingAuthentication: boolean;
}

const state = {
  user: null,
  isAuthenticated: false,
  pendingAuthentication: false
};

const getters = {
  user: (state: State): User | null => { return _cloneDeep(state.user); },
  isAuthenticated: (state: State): boolean => { return state.isAuthenticated; },
  hasActiveSubscription: (state: State): boolean => { return state.user ? state.user.subscribed : false; },
  pendingAuthentication: (state: State): boolean => { return state.pendingAuthentication; },
  userToken: async (): Promise<string> => { return await authHelpers.getUserToken(); }
};

const mutations = {

  setUser: (state: State, user: User) => {
    state.user = user;
  },

  setAuthenticated: (state: State, isAuth: boolean) => {
    state.isAuthenticated = isAuth;
  },

  setPendingAuthentication: (state: State, isPending: boolean) => {
    state.pendingAuthentication = isPending;
  }

};

const actions = {

  login: async ({ commit, dispatch }: any, credentials: any): Promise<void> => {
    if (!User.validateCredentials(credentials)) { return Promise.reject(new Error('invalid-credentials')); }
    const auth = firebase.auth();
    try {
      commit('setPendingAuthentication', true);
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await auth.signInWithEmailAndPassword(credentials.email.toLowerCase(), credentials.password);

      const user = auth.currentUser;
      if (!user) { return Promise.reject(new Error('user-cannot-be-retrieved')); }
      await dispatch('fetchUser', user.uid);
      localStorage.setItem('isAuthenticated', 'true');
      commit('setAuthenticated', true);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    } finally {
      commit('setPendingAuthentication', false);
    }
  },

  logout: async ({ commit }: any): Promise<void> => {
    const auth = firebase.auth();
    try {
      await auth.signOut();
      localStorage.removeItem('isAuthenticated');
      commit('setUser', null);
      commit('setAuthenticated', false);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  autoLogin: async ({ commit, dispatch }: any): Promise<void> => {
    try {
      commit('setPendingAuthentication', true);

      const currentUser = await authHelpers.getCurrentUser();
      if (!currentUser) { commit('setPendingAuthentication', false); return Promise.resolve(); }

      await dispatch('fetchUser', currentUser.uid);
      commit('setAuthenticated', true);
      commit('setPendingAuthentication', false);
      return Promise.resolve();
    } catch (e) {
      dispatch('logout');
      return Promise.reject(e);
    }
  },

  register: async ({ commit, dispatch }: any, credentials: any): Promise<void> => {
    if (!User.validateCredentials(credentials)) { return Promise.reject(new Error('invalid-credentials')); }
    const auth = firebase.auth();

    try {
      commit('setPendingAuthentication', true);

      // Account creation
      await auth.createUserWithEmailAndPassword(credentials.email.toLowerCase(), credentials.password);
      const user = auth.currentUser;
      if (!user) { return Promise.reject(new Error('user-cannot-be-retrieved')); }

      // User entry creation
      const firestore = firebase.firestore();
      const userDocRef = firestore.collection(collections.users).doc(user.uid);
      const userData = {
        ...new User(),
        id: user.uid,
        email: user.email,
        name: credentials.name
      };
      await userDocRef.set(userData);

      // Verification email
      await user.sendEmailVerification();

      await dispatch('fetchUser', user.uid);
      localStorage.setItem('isAuthenticated', 'true');
      commit('setAuthenticated', true);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    } finally {
      commit('setPendingAuthentication', false);
    }
  },

  fetchUser: async ({ commit }: any, id: string | null = null): Promise<User> => {
    const firestore = firebase.firestore();
    try {
      if (!id) {
        const currentUser = await authHelpers.getCurrentUser();
        if (!currentUser) { return Promise.reject(new Error('not-found')); }
        id = currentUser.uid;
      }
      const userDoc = await firestore.collection(collections.users).doc(id).get();
      if (!userDoc.exists) { return Promise.reject(new Error('not-found')); }
      const user = userDoc.data() as User;
      commit('setUser', user);
      return Promise.resolve(user);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  addSubscriptionDuration: async ({ commit, getters }: any, months: number): Promise<void> => {
    if (!months) { return Promise.resolve(); }
    try {
      // Get user
      let user: User = getters.user;
      if (!user) { return Promise.reject(new Error('user-not-found')); }
      // Add subscription duration
      user = {
        ...user,
        subscribed: true,
        subscribed_until: user.subscribed
          ? moment(user.subscribed_until).add(months, 'months').valueOf()
          : moment().add(months, 'months').valueOf()
      };
      // Save
      const firestore = firebase.firestore();
      await firestore.collection(collections.users).doc(user.id)
        .update(_pick(user, ['subscribed', 'subscribed_until']));
      commit('setUser', user);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

};

export default { namespaced: true, state, getters, mutations, actions };
