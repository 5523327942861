import Purchase from '@/shared/models/purchase/Purchase.model';
import firebase from 'firebase/app';
import collections from '@/shared/configs/firebase/database-collections.config';
import { find as _find, cloneDeep as _cloneDeep } from 'lodash';
import User from '@/shared/models/user/User.model';
import { sendPurchaseEmail } from '@/shared/services/purchases/purchases.service';

interface State {
  purchases: Purchase[];
  editedPurchase: Purchase | null;
}

const state: State = {
  purchases: [],
  editedPurchase: null
};

const getters = {
  // ===================================
  // Purchases
  // ===================================

  purchases: (state: State): Purchase[] => { return _cloneDeep(state.purchases); },

  getPurchaseById: (state: State) => (id: string) => { return _cloneDeep(_find(state.purchases, ['id', id])); },

  // ===================================
  // Edited purchase
  // ===================================

  editedPurchase: (state: State) => {
    return state.editedPurchase;
  }
};

const mutations = {
  // ===================================
  // Purchases
  // ===================================

  setPurchases: (state: State, purchases: Purchase[]) => {
    state.purchases = purchases;
  },

  // ===================================
  // Edited purchase
  // ===================================

  setEditedPurchase: (state: State, purchase: Purchase) => {
    state.editedPurchase = purchase;
  },

  clearEditedPurchase: (state: State) => {
    state.editedPurchase = null;
  }
};

const actions = {
  // ===================================
  // Purchases
  // ===================================

  fetchPurchases: async ({ commit, rootGetters }: any): Promise<void> => {
    try {
      const user: User = rootGetters['auth/user'];
      if (!user) { return Promise.reject(new Error('user-not-found')); }
      const result = await firebase.firestore().collection(collections.purchases)
        .where('user.id', '==', user.id)
        .get();
      const purchases = result.docs.map(doc => doc.data());
      commit('setPurchases', purchases);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  createPurchase: async ({ dispatch, rootGetters }: any, purchase: Purchase): Promise<void> => {
    try {
      const user: User = rootGetters['auth/user'];
      if (!user) { return Promise.reject(new Error('user-not-found')); }
      const purchaseDocRef = firebase.firestore().collection(collections.purchases).doc();
      purchase.user = { id: user.id, email: user.email };
      purchase.id = purchaseDocRef.id;
      purchase.status = purchase.paid_with === 'κατάθεση' ? 'εκκρεμής' : 'ολοκληρωμένη';
      if (!Purchase.validate(purchase)) { return Promise.reject(new Error('invalid-purchase')); }
      await purchaseDocRef.set({ ...purchase });
      // Send email
      const token = await rootGetters['auth/userToken'];
      sendPurchaseEmail(token, purchase);
      // Terminate here if purchase is a bank transfer
      if (purchase.paid_with === 'κατάθεση') { return Promise.resolve(); }
      // Add subscription duration
      await dispatch('auth/addSubscriptionDuration', purchase.months, { root: true });
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  // ===================================
  // Edited purchase
  // ===================================

  setEditedPurchase: async ({ commit }: any) => {
    try {
      const purchase = new Purchase();
      await commit('setEditedPurchase', purchase);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  clearEditedPurchase: ({ commit }: any) => {
    commit('clearEditedPurchase');
  }
};

export default { namespaced: true, state, getters, mutations, actions };
