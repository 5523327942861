import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth/auth.store';
import contact from './modules/contact/message.store';
import network from './modules/device/network.store';
import promo from './modules/promo/promo.store';
import purchase from './modules/purchase/purchase.store';
import subscription from './modules/subscription/sub.store';
import video from './modules/video/video.store';
import videoCategory from './modules/video/video-category.store';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    contact,
    network,
    purchase,
    promo,
    subscription,
    video,
    videoCategory
  }
});
