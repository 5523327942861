import Message from '@/shared/models/contact/Message.model';
import firebase from 'firebase/app';
import collections from '@/shared/configs/firebase/database-collections.config';

const state = {};

const getters = {};

const mutations = {};

const actions = {

  sendMessage: async (context: any, message: Message): Promise<void> => {
    try {
      if (!Message.validate(message)) { return Promise.reject(new Error('invalid-message')); }
      const doc = firebase.firestore().collection(collections.contact).doc();
      message = { ...message, id: doc.id };
      await doc.set(message);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

};

export default { namespaced: true, state, getters, mutations, actions };
