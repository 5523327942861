import SubscriptionTier from '@/shared/models/subscriptions/SubscriptionTier.model';
import firebase from 'firebase/app';
import { find as _find, cloneDeep as _cloneDeep } from 'lodash';
import collections from '@/shared/configs/firebase/database-collections.config';

interface State {
  tiers: SubscriptionTier[];
}

const state: State = {
  tiers: []
};

const getters = {

  tiers: (state: State): SubscriptionTier[] => { return _cloneDeep(state.tiers); },

  getTierById: (state: State) => (id: string) => { return _cloneDeep(_find(state.tiers, ['id', id])); }

};

const mutations = {

  setTiers: (state: State, tiers: SubscriptionTier[]) => {
    state.tiers = tiers;
  }

};

const actions = {

  fetchTiers: async ({ commit }: any): Promise<void> => {
    try {
      const result = await firebase.firestore()
        .collection(collections.subscriptions.tiers)
        .where('active', '==', true)
        .get();
      const tiers = result.docs.map(doc => doc.data());
      commit('setTiers', tiers);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

};

export default { namespaced: true, state, getters, mutations, actions };
