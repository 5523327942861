import VideoCategory from '@/shared/models/video/VideoCategory.model';
import { find as _find, cloneDeep as _cloneDeep } from 'lodash';

interface State {
  categories: VideoCategory[];
}

const state: State = {
  categories: [
    {
      id: 'N4uLdbIxTkIVeW16zUzS',
      name: 'Ενδυνάμωση και Functional Training',
      alt_name: 'endynamosi-somatos',
      description: '<span class="text-justify">Είναι γνωστή ως «λειτουργική προπόνηση». Αντλεί στοιχεία από την καθημερινότητα και τα ενσωματώνει' +
        'σε ένα πρόγραμμα γυμναστικής το οποίο περιλαμβάνει ασκήσεις με βάρη, αντιστάσεις, συντονισμού, ισορροπίας και ' +
        'ιδιοδεκτικότητας.</span> <br><br>' +
        'Είναι ιδανικό για:<br>' +
        '- Απώλεια λίπους<br>' +
        '- Φυσική κατάσταση<br>' +
        '- Αύξηση της δύναμης, της ισορροπίας και της ταχύτητας<br>' +
        '- Βελτίωση της καθημερινότητας',
      active: true
    },
    {
      id: 'i8cnpHNArmanXFShDj9Y',
      name: 'HIIT',
      alt_name: 'hiit',
      description: '<span class="text-justify">Το HIIT αποτελείται από σύντομες εκρήξεις έντονης άσκησης που εναλλάσονται ' +
        'με περιόδους ανάκαμψεις χαμηλής έντασης.</span><br><br>' +
        'Είναι ιδανικό για:<br>' +
        '- Απώλεια λίπους<br>' +
        '- Μυϊκή υπερτροφία<br>' +
        '- Βελτιώση της πρόσληψης οξυγόνου<br>' +
        '- Καύση περισσότερων θερμίδων',
      active: true
    },
    {
      id: 'FEAhTxz1RzPEdtOXqJnQ',
      name: 'Pilates',
      alt_name: 'pilates',
      description: '<span class="text-justify">Είναι μια σωματική και ψυχική προπόνηση η οποία επικεντρώνεται στην ισορροπία, ' +
        'την αναπνοή, τη ροή της κίνησης και την συγκέντρωση.</span><br><br>' +
        'Είναι ιδανικό για:<br>' +
        '- Όλους τους ασκουμένους<br>' +
        '- Εκγύμναση ολόκληρου του σώματος<br>' +
        '- Βελτίωση της ευλυγισίας<br>' +
        '- Βοηθά στην απώλεια βάρους<br>' +
        '- Αύξηση των επιπέδων ενέργειας<br>',
      active: true
    },
    {
      id: '6xJ3tzrmxgBSajDjnB7n',
      name: 'Healthy Συνταγές',
      alt_name: 'healthy-syntages',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore' +
        'et dolore magna aliqua. Mauris cursus mattis molestie a iaculis at erat pellentesque adipiscing. Vitae turpis' +
        'massa sed elementum tempus. ',
      active: true
    }
  ]
};

const getters = {

  categories: (state: State): VideoCategory[] => { return _cloneDeep(state.categories); },

  getCategoryByAltName: (state: State) => (altName: string) => { return _cloneDeep(_find(state.categories, ['alt_name', altName])); }

};

const mutations = {};

const actions = {};

export default { namespaced: true, state, getters, mutations, actions };
