import Video from '@/shared/models/video/Video.model';
import firebase from 'firebase/app';
import { find as _find, cloneDeep as _cloneDeep } from 'lodash';
import collections from '@/shared/configs/firebase/database-collections.config';

interface State {
  videos: Video[];
}

const state: State = {
  videos: []
};

const getters = {

  videos: (state: State): Video[] => { return _cloneDeep(state.videos); },

  getVideosByCategory: (state: State) => (category: string) => {
    return _cloneDeep(state.videos.filter(video => video.categories.includes(category)));
  },

  getVideoById: (state: State) => (id: string) => { return _cloneDeep(_find(state.videos, ['id', id])); }

};

const mutations = {

  setVideos: (state: State, videos: Video[]) => {
    state.videos = videos;
  },

  replaceVideo: (state: State, video: Video) => {
    state.videos = state.videos.map(entry => {
      return entry.id === video.id ? video : entry;
    });
  }

};

const actions = {

  fetchVideos: async ({ commit }: any): Promise<void> => {
    try {
      const result = await firebase.firestore()
        .collection(collections.videos)
        .where('active', '==', true)
        .orderBy('uploaded_at', 'desc')
        .get();
      const videos = result.docs.map(doc => doc.data());
      commit('setVideos', videos);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  fetchVideosByCategory: async ({ commit }: any, category: string): Promise<void> => {
    try {
      if (!category) { return; }
      const result = await firebase.firestore()
        .collection(collections.videos)
        .where('active', '==', true)
        .where('categories', 'array-contains', category)
        .orderBy('uploaded_at', 'desc')
        .get();
      const videos = result.docs.map(doc => doc.data());
      commit('setVideos', videos);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  fetchVideoById: async ({ commit }: any, id: string): Promise<Video> => {
    try {
      const result = await firebase.firestore().collection(collections.videos).doc(id).get();
      if (!result.exists) { return Promise.reject(new Error('not-found')); }
      const video = result.data() as Video;
      commit('replaceVideo', video);
      return Promise.resolve(video);
    } catch (e) {
      return Promise.reject(e);
    }
  }

};

export default { namespaced: true, state, getters, mutations, actions };
