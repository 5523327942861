import moment from 'moment';

/**
 * Format date to locale date and given format
 *
 * @param value Date to format
 * @param dateFormat Desired date format
 * @return {string}
 */
function localeDate (value: any, dateFormat: string): string {
  if (!value) { return value; }
  const date = moment(value);
  if (!date.isValid()) { return value; }
  return date.format(dateFormat);
}

/**
 * Format date to utc and given format
 *
 * @param value Date to format
 * @param dateFormat Desired date format
 * @return {string}
 */
function utcDate (value: any, dateFormat: string): string {
  if (!value) { return value; }
  const date = moment.utc(value);
  if (!date.isValid()) { return value; }
  return date.format(dateFormat);
}

export { localeDate, utcDate };
