<template>
  <v-footer padless>
    <v-container>
      <v-row>
        <!-- Links -->
        <v-col cols="12" class="text-center">
          <v-btn v-for="link in links" :key="link.text" text @click="onGoTo(link.route)">
            {{ link.text.toLowerCase() }}
          </v-btn>
        </v-col>

        <!-- Social -->
        <v-col cols="12" class="text-center">
          <v-btn v-for="entry in social" :key="entry.text" icon class="mr-2" @click="onSocialClicked(entry)">
            <v-icon>{{ entry.icon }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-center">
          Maridela © {{ new Date().getFullYear() }}<br>
          <small>v{{ appVersion }}</small>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({

  data () {
    return {
      links: [
        { text: 'συχνες ερωτησεις', route: 'FAQ' },
        { text: 'τροποι πληρωμης', route: 'Payment-Methods' },
        { text: 'πολιτικη απορρητου', route: 'Privacy-Policy' },
        { text: 'επικοινωνια', route: 'Contact' }
      ],
      social: [
        { text: '@maridela_fitgirl', icon: 'mdi-instagram', routeName: '', href: 'https://www.instagram.com/maridela_fitgirl/' },
        { text: 'επικοινωνία', icon: 'mdi-chat', routeName: 'Contact', href: '' },
        { text: 'info@maridela.gr', icon: 'mdi-email', routeName: '', href: 'mailto:info@maridela.gr?subject=Μήνυμα επικοινωνίας' }
      ],
      appVersion: process.env.VUE_APP_VERSION
    };
  },

  methods: {
    onGoTo (routeName) {
      if (!routeName) { return; }
      this.$router.push({ name: routeName });
    },

    onSocialClicked (entry) {
      if (!entry) { return; }
      if (entry.routeName) {
        this.onGoTo(entry.routeName);
      } else {
        window.open(entry.href);
      }
    }
  }
});
</script>
