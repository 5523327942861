interface State {
  networkState: string;
}

const state: State = {
  networkState: 'online'
};

const getters = {

  networkState: (state: State): string => { return state.networkState; },

  isOnline: (state: State): boolean => { return state.networkState === 'online'; },

  isOffline: (state: State): boolean => { return state.networkState === 'offline'; }

};

const mutations = {

  setNetworkState: (state: State, networkState: boolean): void => {
    state.networkState = networkState ? 'online' : 'offline';
  }

};

const actions = {

  initNetworkStateListeners: async ({ commit }: any): Promise<void> => {
    // @ts-ignore
    if (!navigator?.onLine || !navigator?.connection) { return; }

    try {
      // @ts-ignore
      navigator.connection.addEventListener('change', () => { commit('setNetworkState', navigator.onLine); });
      commit('setNetworkState', navigator.onLine);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

};

export default { namespaced: true, state, getters, mutations, actions };
