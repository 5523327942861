import firebase from 'firebase/app';

function getCurrentUser (): Promise<firebase.User | null> {
  return new Promise((resolve, reject) => {
    const auth = firebase.auth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      resolve(currentUser);
    } else {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    }
  });
}

async function getUserToken (): Promise<string> {
  try {
    const currentUser = await getCurrentUser();
    if (!currentUser) { return Promise.resolve(''); }
    const tokenResult = await currentUser.getIdTokenResult();
    return tokenResult.token;
  } catch (e) {
    return Promise.reject(e);
  }
}

export { getCurrentUser, getUserToken };
