import { truncate as _truncate, isString as _isString } from 'lodash';

/**
 * Truncates the provided string
 *
 * @param {String} string String to be truncated
 * @param {Number} length Length of the truncated string
 * @return {string}
 */
export function truncate (string: string, length: number): string {
  if (!_isString(string)) { return string; }
  return _truncate(string, { length, separator: ' ' });
}
