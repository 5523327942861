import { isString as _isString, isNumber as _isNumber, isEmpty as _isEmpty } from 'lodash';
import moment from 'moment';

interface PurchaseI {
  id: string;
  months: number;
  price: number;
  purchased_at: number;
  paid_with: string; // 'paypal' | 'χρεωστική κάρτα' | 'κατάθεση'
  status: string; // 'εκκρεμής' | 'ολοκληρωμένη'
  user: { id: string; email: string };
  transfer_id?: string;
}

class Purchase implements PurchaseI {
  public id = '';
  public months = 0;
  public price = 0;
  public purchased_at: number = moment().valueOf();
  public paid_with = '';
  public status = '';
  public transfer_id = '';
  public user = {
    id: '',
    email: ''
  }

  static validate (purchase: Purchase): boolean {
    if (!purchase) { return false; }
    const p = purchase;
    return _isString(p.id) && !_isEmpty(p.id.trim()) &&
      _isNumber(p.months) && p.months > 0 &&
      _isNumber(p.price) && p.price >= 0 &&
      _isNumber(p.purchased_at) && p.purchased_at > 0 &&
      _isString(p.paid_with) && !_isEmpty(p.paid_with.trim()) &&
      _isString(p.status) && !_isEmpty(p.status.trim()) &&
      _isString(p.user.id) && !_isEmpty(p.user.id.trim()) &&
      _isString(p.user.email) && !_isEmpty(p.user.email.trim()) &&
      p.transfer_id ? (_isString(p.transfer_id) && !_isEmpty(p.transfer_id.trim())) : true;
  }
}

export default Purchase;
export { Purchase };
