import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate';
import filters from './shared/filters/filters';
import VueToast from 'vue-toast-notification';

import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import firebaseConfig from '@/shared/configs/firebase/firebase.config';

Vue.use(Vuelidate);
Vue.use(filters);
Vue.use(VueToast);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created () {
    firebase.initializeApp(firebaseConfig);
    firebase.firestore().settings({
      cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
    });
    firebase.firestore().enablePersistence();
  }
}).$mount('#app');
