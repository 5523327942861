import { truncate } from './truncate/truncate.filter';
import { localeDate, utcDate } from './date/date.filter';

export default {
  install (Vue: any) {
    Vue.filter('truncate', truncate);
    Vue.filter('localeDate', localeDate);
    Vue.filter('utcDate', utcDate);
  }
};
