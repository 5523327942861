import tiers from './subscription-tier.store';

const state = {};
const getters = {};
const mutations = {};
const actions = {};
const modules = {
  tiers
};

export default { namespaced: true, state, getters, mutations, actions, modules };
