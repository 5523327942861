











import Vue from 'vue';

export default Vue.extend({
  name: 'App',

  data: () => ({
    pendingComponentInit: false
  }),

  created () {
    const theme = localStorage.getItem('theme');
    if (!theme) { localStorage.setItem('theme', 'dark'); }
    this.initComponent();
  },

  methods: {

    async initComponent () {
      if (this.pendingComponentInit) { return; }
      try {
        this.pendingComponentInit = true;
        await this.$store.dispatch('network/initNetworkStateListeners');
        await this.$store.dispatch('auth/autoLogin');
      } catch (e) {
        console.log(e);
      } finally {
        this.pendingComponentInit = false;
      }
    }
  }
});
