import PromoCode from '@/shared/models/promo/PromoCode.model';
import firebase from 'firebase/app';
import collections from '@/shared/configs/firebase/database-collections.config';

const state = {};

const getters = {};

const mutations = {};

const actions = {

  fetchPromoCode: async (context: any, code: string): Promise<PromoCode> => {
    try {
      const result = await firebase.firestore()
        .collection(collections.promo.codes)
        .where('active', '==', true)
        .where('code', '==', code)
        .get();
      if (result.docs.length === 0) { return Promise.reject(new Error('promo-code-not-found')); }

      const returnedCode = result.docs[0].data() as PromoCode;
      return Promise.resolve(returnedCode);
    } catch (e) {
      return Promise.reject(e);
    }
  },

  addPromoCodeUse: async (context: any, code_id: string): Promise<void> => {
    try {
      await firebase.firestore()
        .collection(collections.promo.codes)
        .doc(code_id)
        .update({ times_used: firebase.firestore.FieldValue.increment(1) });

      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

};

export default { namespaced: true, state, getters, mutations, actions };
