<template>
  <div>

    <!-- Navbar -->
    <app-header style="z-index: 999;" />

    <!-- Main -->
    <v-main>
      <router-view></router-view>
    </v-main>

    <app-footer />

  </div>
</template>

<script>
import AppHeader from '@/components/shared/header/Header.component.vue';
import AppFooter from '@/components/shared/footer/Footer.component.vue';

export default {

  name: 'Landing',

  components: {
    AppHeader,
    AppFooter
  }

};
</script>
