import Purchase from '@/shared/models/purchase/Purchase.model';
import apiRoutes from '@/shared/configs/api/api-routes.config';

async function sendPurchaseEmail (token: string, purchase: Purchase): Promise<void> {
  if (!token || !purchase) { return; }
  try {
    const URL = `${process.env.VUE_APP_API_URL}/${apiRoutes.emails.sendPurchaseEmail}`.replace(':id', purchase.id);
    await fetch(URL, {
      method: 'POST',
      body: JSON.stringify(purchase),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`
      }
    });
  } catch (e) {
    return Promise.reject(e);
  }
}

export { sendPurchaseEmail };
