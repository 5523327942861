import moment from 'moment';
import { isString as _isString, isEmpty as _isEmpty } from 'lodash';

class Message {
  public id?: string;
  public type = ''; // 'επικοινωνία' | 'feedback'
  public name = '';
  public email = '';
  public subject = '';
  public message = '';
  public replied = false;
  public app_version = process.env.VUE_APP_VERSION;
  public created_at = moment().valueOf();

  constructor (type: string) {
    this.type = type;
  }

  static validate (message: Message): boolean {
    if (!message) { return false; }
    const m = message;
    return _isString(m.type) && !_isEmpty(m.type.trim()) &&
    _isString(m.name) && !_isEmpty(m.name.trim()) &&
    _isString(m.email) && !_isEmpty(m.email.trim()) &&
    _isString(m.subject) && !_isEmpty(m.subject.trim()) &&
    _isString(m.message) && !_isEmpty(m.message.trim());
  }
}

export default Message;
