import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Landing from '@/views/base-layout/BaseLayout.view.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // Global routes
  {
    path: '/',
    component: Landing,
    children: [
      { path: '', name: 'Landing', component: () => import('../components/landing/Landing.component.vue') },
      { path: 'faq', name: 'FAQ', component: () => import('../components/faq/Faq.component.vue') },
      { path: 'privacy-policy', name: 'Privacy-Policy', component: () => import('../components/privacy-policy/PrivacyPolicy.component.vue') },
      { path: 'payment-methods', name: 'Payment-Methods', component: () => import('../components/payment-methods/PaymentMethods.component.vue') },
      { path: 'contact', name: 'Contact', component: () => import('../components/contact-us/ContactUs.component.vue') }
    ]
  },

  // Auth route
  {
    path: '/auth',
    name: 'Authentication',
    component: () => import('../views/auth/Auth.view.vue'),
    children: [
      {
        path: 'login',
        name: 'Authentication:Login',
        component: () => import('../components/auth/Login.component.vue')
      },
      {
        path: 'register',
        name: 'Authentication:Register',
        component: () => import('../components/auth/Register.component.vue')
      }
    ]
  },

  // App routes
  {
    path: '/app',
    name: 'App:Layout',
    component: () => import('../views/app/layout/Layout.view.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '/dashboard',
        name: 'App:Dashboard',
        component: () => import('../views/app/dashboard/Dashboard.view.vue')
      },
      {
        path: '/subscription-tiers',
        name: 'App:Subscription-Tiers',
        component: () => import('../views/app/subscriptions/SubscriptionTiers.view.vue')
      },
      {
        path: '/categories/:category',
        name: 'App:Video-Categories',
        component: () => import('../views/app/videos/VideoCategory.view.vue')
      }
    ]
  },

  // Error
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthed = localStorage.getItem('isAuthenticated');
    isAuthed ? next() : next({ name: 'Landing' });
  } else {
    next();
  }
});

export default router;
