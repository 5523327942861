const collections = {
  contact: 'messages',
  users: 'users',
  videos: 'videos',
  videoCategories: 'video-categories',
  subscriptions: {
    tiers: 'subscription-tiers'
  },
  promo: {
    codes: 'promo-codes'
  },
  purchases: 'purchases'
};

export default collections;
